<template>
  <div class="h-100">
    <v-row class="h-100 ma-0" no-gutters>
      <v-col cols="4" v-if="!$vuetify.breakpoint.smAndDown"
             class="h-100 d-flex justify-center align-center mr-10 background--yankee-blue">
        <v-img min-width="50px" contain src="@/assets/Logo_S2.png">
        </v-img>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndDown" cols="1"></v-col>
      <v-col class="d-flex align-center pr-16">
        <v-card flat color="body-background">
          <v-row v-if="!$vuetify.breakpoint.smAndDown" class="ma-12"></v-row>
          <v-row>
            <v-card-title>
              <v-row class="align-baseline">
                <h3>
                  <span class="grey--text">Welkom bij</span>
                </h3>
                <v-img :width="$vuetify.breakpoint.smAndDown ? '160px' : '320px'" contain src="@/assets/Logo_blauw.png"
                       :class="$vuetify.breakpoint.smAndDown ? 'ml-1' : 'ml-n7'"/>
              </v-row>
            </v-card-title>
            <v-card-text class="mt-4 pa-1">
              <h2>
                Nieuw wachtwoord instellen
              </h2>
            </v-card-text>
            <v-card-text class="pt-10">
              <v-form v-model="valid">
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-text-field readonly outlined filled persistent-placeholder type="email" v-model.trim="email" label="Email" hide-details="auto"  />
                  </v-col>
                  <v-col cols="12">
                    <PasswordInput v-model="password" :rules="passwordRules" :tabindex="1" :required="true" @enter-pressed="submitPasswordReset" :cols="8" persistent-placeholder filled outlined hide-details="auto"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-block pt-0">
              <div class="mb-4">
                <v-btn type="button" color="secondary" tabindex="3" @click="submitPasswordReset">
                  Vernieuwen
                </v-btn>
              </div>
              <div class="text-body-2 mb-2">
                <span>Toch aanmelden?</span>
                <router-link :to="{name: routeNames.LOGIN}" class="pl-1 text-decoration-none" tabindex="4">
                  Klik hier.
                </router-link>
              </div>
              <div class="text-body-2">
                <span>Nieuw bij Buildbase?</span>
                <router-link :to="{name: routeNames.PAYMENT}" class="pl-1 text-decoration-none" tabindex="5">
                  Registreer.
                </router-link>
              </div>
            </v-card-actions>
          </v-row>
          <v-row class="ma-16"></v-row>
        </v-card>
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="4"></v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RouteNames from "@/router/RouteNames";
import PasswordInput from "@/components/useroverview/PasswordInput.vue";
import {getPasswordRules} from "@/shared/utils/inputRulesUtils"

export default {
  name: "PasswordResetToken",
  components: {PasswordInput},
  data() {
    return {
      valid: false,
      email: null,
      password: null,
      resetToken: null,
      passwordRules: getPasswordRules(),
      routeNames: RouteNames,
      tokenIsValid: false,
    }
  },
  async created(){
    this.email = this.$route.query.email
    this.resetToken = this.$route.query.resetToken

    await this.$store.dispatch('authModule/validatePasswordResetToken', {email: this.email.toLowerCase(), resetToken: this.resetToken})
        .then((result) => this.tokenIsValid = result);
  },
  methods: {
    submitPasswordReset() {
      const request = {
        email: this.email.toLowerCase(),
        newPassword: this.password,
        resetToken: this.resetToken,
      }
      this.$store.dispatch("authModule/updateOwnPasswordToken", request)
    },
  }
}
</script>

<style lang="scss">
div.v-input.v-text-field--outlined.v-text-field--filled {
  div.v-input__slot {
    input {
      margin-top: 12px;
    }
  }
}
</style>
